// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-converter-converter-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/templates/converter/converter.js" /* webpackChunkName: "component---src-templates-converter-converter-js" */),
  "component---src-templates-implementation-implementation-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/templates/implementation/implementation.js" /* webpackChunkName: "component---src-templates-implementation-implementation-js" */),
  "component---src-templates-language-language-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/templates/language/language.js" /* webpackChunkName: "component---src-templates-language-language-js" */),
  "component---src-templates-algorithm-algorithm-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/templates/algorithm/algorithm.js" /* webpackChunkName: "component---src-templates-algorithm-algorithm-js" */),
  "component---src-templates-index-index-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/templates/index/index.js" /* webpackChunkName: "component---src-templates-index-index-js" */),
  "component---src-static-pages-terms-of-service-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/static_pages/terms_of_service.js" /* webpackChunkName: "component---src-static-pages-terms-of-service-js" */),
  "component---src-static-pages-about-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/static_pages/about.js" /* webpackChunkName: "component---src-static-pages-about-js" */),
  "component---src-static-pages-privacy-policy-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/static_pages/privacy_policy.js" /* webpackChunkName: "component---src-static-pages-privacy-policy-js" */),
  "component---src-pages-404-js": () => import("/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}


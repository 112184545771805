module.exports = [{
      plugin: require('/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":498},
    },{
      plugin: require('/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-66923864-5"},
    },{
      plugin: require('/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/claasmeiners/WebstormProjects/AlgorithmsAndTechnologies/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
